import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { checkAccessCode } from '../utils/api';

function AccessControl({ onAccessGranted }) {
  const [accessCode, setAccessCode] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await checkAccessCode(accessCode);
      if (response.isValid) {
        onAccessGranted(response.isAdmin);
      } else {
        setMessage('코드를 올바르게 입력하세요');
      }
    } catch (error) {
      setMessage('오류: 접근코드가 틀렸습니다.');
    }
  };

  return (
    <div>
      <h2>접근 제어</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>접근 코드 입력</Form.Label>
          <Form.Control
            type="text"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            placeholder="접근 코드 입력 ㄱㄱ"
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          접근하기
        </Button>
      </Form>
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}
    </div>
  );
}

export default AccessControl;
