import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, Alert, Button, Nav, Tabs, Tab } from 'react-bootstrap';
import AccessControl from './components/AccessControl';
import AdminControl from './components/AdminControl';
import LoginForm from './components/LoginForm';
import SearchForm from './components/SearchForm';
import TrainList from './components/TrainList';
import TaskManager from './components/TaskManager';
import LoadingSpinner from './components/LoadingSpinner';
import { checkLogin, fetchStations, searchTrains, reserveTrain, checkCurrentTask, cancelTask, getUserTasks } from './utils/api';
import debounce from 'lodash/debounce';

function App() {
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [stations, setStations] = useState([]);
  const [trains, setTrains] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReserving, setIsReserving] = useState(false);
  const [isDateChanging, setIsDateChanging] = useState(false);
  const [activeTab, setActiveTab] = useState('search');
  const [tasks, setTasks] = useState([]);
  const [maxTasks, setMaxTasks] = useState(3); // 서버의 MAX_TASKS_PER_USER와 동일한 값

  const today = new Date().toISOString().split('T')[0];  // YYYY-MM-DD 형식의 오늘 날짜
  const [searchParams, setSearchParams] = useState({ departure: '', arrival: '', date: today });
  const handleAccessGranted = (admin) => {
    setIsAccessGranted(true);
    setIsAdmin(admin);
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchTasks = async () => {
        try {
          const response = await getUserTasks(credentials.username);
          setTasks(response.tasks);
        } catch (error) {
          setMessage('매크로 목록을 불러오는데 실패했습니다.');
        }
      };

      fetchTasks();
      // 3초마다 태스크 목록 갱신
      const interval = setInterval(fetchTasks, 3000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn, credentials.username]);

  const fetchUserTasks = async () => {
    try {
      const response = await getUserTasks(credentials.username);
      setTasks(response.tasks);
    } catch (error) {
      setMessage('태스크 목록을 불러오는데 실패했습니다.');
    }
  };

  useEffect(() => {
    fetchStations().then(setStations).catch(() => setMessage('역 목록을 불러오는데 실패했습니다.'));
  }, []);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await checkLogin(credentials);
      if (response.status === 'success') {
        setIsLoggedIn(true);
        setMessage('로그인 성공');
        if (response.current_task) {
          setCurrentTask(response.current_task);
        }
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage('로그인 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = useCallback(async (params = searchParams) => {
    setIsLoading(true);
    setTrains([]); // 검색 시작 시 기존 결과 초기화
    try {
      const results = await searchTrains({ ...params, ...credentials });
      setTrains(results);
      setMessage('열차 검색 완료');
    } catch (error) {
      setMessage('열차 검색 실패: ' + (error.response?.data?.detail || error.message));
      setTrains([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchParams, credentials]);

    // 디바운스된 검색 함수
    const debouncedSearch = useCallback(
      debounce((params) => handleSearch(params), 300),
      [handleSearch]
    );

  const handleDateChange = async (direction) => {
    setIsDateChanging(true);
    const currentDate = new Date(searchParams.date);
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + (direction === 'next' ? 1 : -1));
    const newDateString = newDate.toISOString().split('T')[0];
    
    const newSearchParams = { ...searchParams, date: newDateString };
    setSearchParams(newSearchParams);
    
    try {
      // 새로운 날짜로 검색 수행
      await handleSearch(newSearchParams);
    } finally {
      setIsDateChanging(false);
    }
  };

  const handleReserve = async (train) => {
    setIsLoading(true);
    try {
      if (tasks.length >= maxTasks) {
        setMessage(`매크로는 최대 ${maxTasks}개까지만 실행할 수 있습니다.`);
        return;
      }

      const response = await reserveTrain({
        username: credentials.username,
        password: credentials.password,
        train_code: train.train_code,
        train_number: train.train_number,
        dep_date: train.dep_date,
        dep_time: train.dep_time,
        departure: train.departure,
        arrival: train.arrival
      });
      
      setMessage(`예약 매크로가 시작되었습니다. (${response.current_task_count}/${response.max_tasks})`);
      const tasksResponse = await getUserTasks(credentials.username);
      setTasks(tasksResponse.tasks);
      setActiveTab('tasks');
    } catch (error) {
      setMessage('예약 요청 실패: ' + (error.response?.data?.detail || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const checkTaskStatus = async () => {
    setIsLoading(true);
    try {
      const response = await checkCurrentTask(credentials.username);
      if (response.task_info) {
        setCurrentTask(response.task_info);
        setIsReserving(true);
      } else {
        setCurrentTask(null);
        setIsReserving(false);
      }
    } catch (error) {
      setMessage('태스크 확인 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelTask = async (taskId) => {
    try {
      await cancelTask(taskId);
      // 태스크 목록 갱신
      const response = await getUserTasks(credentials.username);
      setTasks(response.tasks);
      setMessage('매크로가 취소되었습니다.');
    } catch (error) {
      setMessage('매크로 취소에 실패했습니다: ' + (error.response?.data?.detail || error.message));
    }
  };

  // 로그인된 상태의 메인 컨텐츠 렌더링
  const renderMainContent = () => {
    if (!isLoggedIn) {
      return (
        <LoginForm 
          credentials={credentials} 
          setCredentials={setCredentials} 
          onLogin={handleLogin} 
        />
      );
    }

    return (
      <>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-4"
        >
          <Tab eventKey="search" title="열차 검색">
            <SearchForm 
              stations={stations} 
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              onSearch={debouncedSearch}
            />
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              trains.length > 0 && (
                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Button 
                      variant="outline-secondary" 
                      onClick={() => handleDateChange('prev')}
                      disabled={searchParams.date === today || isDateChanging}
                    >
                      &lt; 이전 날짜
                    </Button>
                    <span>{searchParams.date}</span>
                    <Button 
                      variant="outline-secondary" 
                      onClick={() => handleDateChange('next')}
                      disabled={isDateChanging}
                    >
                      다음 날짜 &gt;
                    </Button>
                  </div>
                  <TrainList 
                    trains={trains} 
                    onReserve={handleReserve}
                    searchParams={searchParams}
                  />
                </div>
              )
            )}
          </Tab>
          <Tab 
            eventKey="tasks" 
            title={`실행 중인 매크로 (${tasks.length}/${maxTasks})`}
          >
            <TaskManager 
              tasks={tasks}
              onCancelTask={handleCancelTask}
              maxTasks={maxTasks}
            />
          </Tab>
        </Tabs>
      </>
    );
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">SRT</h1>
              
              {message && (
                <Alert 
                  variant="info" 
                  className="mb-3" 
                  onClose={() => setMessage('')} 
                  dismissible
                >
                  {message}
                </Alert>
              )}

              {!isAccessGranted ? (
                <AccessControl onAccessGranted={handleAccessGranted} />
              ) : (
                <>
                  {isAdmin && <AdminControl />}
                  {renderMainContent()}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;