import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const pulseAnimation = keyframes`
  0%, 100% { opacity: 0.5; transform: scale(0.95); }
  50% { opacity: 1; transform: scale(1.05); }
`;

const moveAndAlignAnimation = keyframes`
  0%, 100% { transform: translate(0, 0); opacity: 0.7; }
  25% { transform: translate(-10px, -10px); opacity: 0.3; }
  50% { transform: translate(10px, 10px); opacity: 0.5; }
  75% { transform: translate(-5px, 5px); opacity: 0.3; }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
`;

const SpinnerSVG = styled.svg`
  width: 150px;
  height: 150px;
  animation: ${rotateAnimation} 20s linear infinite;
`;

const Circle = styled.circle`
  fill: none;
  stroke: rgba(100, 100, 100, 0.2);
  stroke-width: 3;
  stroke-linecap: round;
`;

const SYGText = styled.text`
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  fill: rgba(100, 100, 100, 0.7);
  animation: ${moveAndAlignAnimation} 4s ease-in-out infinite;
`;

const LoadingText = styled.div`
  position: absolute;
  bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  color: rgba(100, 100, 100, 0.8);
  text-transform: uppercase;
  letter-spacing: 3px;
  animation: ${pulseAnimation} 2s ease-in-out infinite;
`;

function LoadingSpinner() {
  return (
    <Container>
      <SpinnerSVG viewBox="0 0 100 100">
        {/* <Circle cx="50" cy="50" r="45" strokeDasharray="283" strokeDashoffset="100" /> */}
        {/* <Circle cx="50" cy="50" r="35" strokeDasharray="220" strokeDashoffset="40" /> */}
        
        <SYGText x="38" y="48" textAnchor="middle" style={{ animationDelay: '0s' }}>S</SYGText>
        <SYGText x="50" y="60" textAnchor="middle" style={{ animationDelay: '1.3s' }}>Y</SYGText>
        <SYGText x="62" y="48" textAnchor="middle" style={{ animationDelay: '2.6s' }}>G</SYGText>
        
        {/* <line x1="50" y1="20" x2="50" y2="30" stroke="rgba(100,100,100,0.2)" strokeWidth="2" /> */}
        {/* <line x1="50" y1="70" x2="50" y2="80" stroke="rgba(100,100,100,0.2)" strokeWidth="2" /> */}
        {/* <line x1="20" y1="50" x2="30" y2="50" stroke="rgba(100,100,100,0.2)" strokeWidth="2" /> */}
        {/* <line x1="70" y1="50" x2="80" y2="50" stroke="rgba(100,100,100,0.2)" strokeWidth="2" /> */}
      </SpinnerSVG>
      <LoadingText>Loading</LoadingText>
    </Container>
  );
}

export default LoadingSpinner;