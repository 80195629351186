import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import { Search, MapPin, Calendar, ArrowRight } from 'lucide-react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const StyledCard = styled(Card)`
  animation: ${fadeIn} 0.5s ease-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
`;

const StyledButton = styled(Button)`
  background-color: #4A90E2;
  border: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #357ABD;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const IconWrapper = styled.span`
  margin-right: 0.5rem;
  color: #4A90E2;
`;

function SearchForm({ stations, searchParams, setSearchParams, onSearch }) {
    const [isSwapping, setIsSwapping] = useState(false);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onSearch(searchParams);
    };
  
    const swapStations = () => {
      setIsSwapping(true);
      setTimeout(() => {
        setSearchParams({
          ...searchParams,
          departure: searchParams.arrival,
          arrival: searchParams.departure
        });
        setIsSwapping(false);
      }, 300);
    };
  
    const today = new Date().toISOString().split('T')[0];
  
    return (
      <StyledCard className="mb-4">
        <Card.Body>
          <h4 className="mb-4 text-center">
            <IconWrapper><Search size={24} /></IconWrapper>
            열차 검색
          </h4>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 align-items-end">
              <Form.Group as={Col} md={4}>
                <Form.Label>
                  <IconWrapper><MapPin size={18} /></IconWrapper>
                  출발지
                </Form.Label>
                <Form.Select
                  value={searchParams.departure}
                  onChange={(e) => setSearchParams({...searchParams, departure: e.target.value})}
                  required
                >
                  <option value="">출발지 선택</option>
                  {stations.map(station => (
                    <option key={station} value={station}>{station}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Col md={1} className="text-center mb-3">
                <Button 
                  variant="link" 
                  onClick={swapStations} 
                  className={`p-0 ${isSwapping ? 'rotate-animation' : ''}`}
                >
                  <ArrowRight size={24} />
                </Button>
              </Col>
              <Form.Group as={Col} md={4}>
                <Form.Label>
                  <IconWrapper><MapPin size={18} /></IconWrapper>
                  도착지
                </Form.Label>
                <Form.Select
                  value={searchParams.arrival}
                  onChange={(e) => setSearchParams({...searchParams, arrival: e.target.value})}
                  required
                >
                  <option value="">도착지 선택</option>
                  {stations.map(station => (
                    <option key={station} value={station}>{station}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md={3}>
                <Form.Label>
                  <IconWrapper><Calendar size={18} /></IconWrapper>
                  날짜
                </Form.Label>
                <Form.Control
                  type="date"
                  value={searchParams.date}
                  onChange={(e) => setSearchParams({...searchParams, date: e.target.value})}
                  min={today}
                  required
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <StyledButton variant="primary" type="submit" size="lg">
                <Search size={18} className="me-2" />
                열차 검색
              </StyledButton>
            </div>
          </Form>
        </Card.Body>
      </StyledCard>
    );
  }
  
  export default SearchForm;