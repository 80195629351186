import React, { useState, useEffect } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { getCurrentAccessCode, generateAccessCode } from '../utils/api';

function AdminControl() {
  const [currentAccessCode, setCurrentAccessCode] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchCurrentAccessCode();
  }, []);

  const fetchCurrentAccessCode = async () => {
    try {
      const response = await getCurrentAccessCode();
      setCurrentAccessCode(response.code);
    } catch (error) {
      setMessage('현재 접근 코드를 가져오는데 실패했습니다.');
    }
  };

  const handleGenerateCode = async () => {
    try {
      const response = await generateAccessCode();
      setCurrentAccessCode(response.code);
      setMessage(`새로운 접근 코드가 생성되었습니다: ${response.code}`);
    } catch (error) {
      setMessage('접근 코드 생성 중 오류가 발생했습니다.');
    }
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>관리자 제어</Card.Title>
        <p>현재 접근 코드: {currentAccessCode}</p>
        <Button variant="info" onClick={handleGenerateCode}>
          새 접근 코드 생성
        </Button>
        {/* <Button variant="secondary" onClick={fetchCurrentAccessCode} className="ml-2">
          접근 코드 갱신
        </Button> */}
        {message && <Alert variant="info" className="mt-3">{message}</Alert>}
      </Card.Body>
    </Card>
  );
}

export default AdminControl;